import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  languageCode: string
  slides: ItemProps[]
}

export const OffersSlider = memo(function OffersSlider({
  languageCode,
  slides,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: slides.length > 2 ? true : false,
    slides: {
      perView: 2,
      spacing: 72,
    },
    breakpoints: {
      '(max-width: 767px)': {
        slides: {
          perView: 1.05,
          spacing: 12,
        },
      },
    },
  })

  return (
    <Container>
      <Title>{useVocabularyData('other-offers', languageCode)}</Title>

      <Wrapper>
        <Slider ref={sliderRef}>
          {slides.map((item, index) => (
            <Item key={index} className="keen-slider__slide" {...item} />
          ))}
        </Slider>

        {slides.length > 2 ? (
          <Arrows dial={5} row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              variant="square"
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              variant="square"
            />
          </Arrows>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  padding: 4.625rem 0;
  position: relative;
  text-align: center;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.875rem;
`

const Wrapper = styled.div`
  padding: 0 10.972vw;
  position: relative;
  &::before {
    content: '';
    display: block;
    height: 7rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1142.027" height="28.204" viewBox="0 0 1142.027 28.204"><path d="M0,.5C21.947.5,21.947,24.7,43.9,24.7S65.853.5,87.812.5,109.759,24.7,131.7,24.7,153.654.5,175.607.5s21.959,24.2,43.918,24.2S241.472.5,263.419.5s21.959,24.2,43.918,24.2S329.29.5,351.25.5,373.2,24.7,395.162,24.7,417.121.5,439.074.5,461.039,24.7,483,24.7,504.958.5,526.917.5s21.965,24.2,43.93,24.2S592.812.5,614.778.5s21.965,24.2,43.93,24.2S680.673.5,702.632.5,724.6,24.7,746.569,24.7,768.54.5,790.511.5c21.953,0,21.953,24.2,43.906,24.2S856.383.5,878.348.5s21.965,24.2,43.93,24.2S944.243.5,966.209.5s21.971,24.2,43.943,24.2S1032.129.5,1054.112.5s21.971,24.2,43.943,24.2S1120.038.5,1142.027.5" transform="translate(0 1.5)" fill="none" stroke="%23E2DFDA" stroke-miterlimit="10" stroke-width="4"/></svg>');
    opacity: 0.15;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
  }
`

const Slider = styled.div`
  display: flex;
  margin-top: 3rem;
  overflow: hidden;
  text-align: left;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 767px) {
    overflow: visible;
    padding-left: 0.5rem;
  }
`

const Arrows = styled(FlexBox)`
  margin-top: 2.5rem;
`

export type Variant = 'default' | 'offers'
