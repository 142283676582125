import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  bookingButtonURL?: string
  contactsPageURL?: string
  languageCode: string
}

export const BookingCTA = memo(function BookingCTA({
  bookingButtonURL,
  contactsPageURL,
  languageCode,
}: Props) {
  return (
    <Container>
      <FadeInUp>
        <Title className="wave">
          {useVocabularyData('offer-be-title', languageCode)}
        </Title>
      </FadeInUp>

      <FadeInUp>
        {bookingButtonURL || contactsPageURL ? (
          <Buttons dial={5} row wrap>
            <Button
              label={useVocabularyData('book', languageCode)}
              rel="noopener"
              target="_blank"
              URL={bookingButtonURL}
              variant="secondary"
            />

            {contactsPageURL ? (
              <Button
                label={useVocabularyData('request-info', languageCode)}
                URL={contactsPageURL}
              />
            ) : null}
          </Buttons>
        ) : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  margin: -1.875rem auto 7.3125rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin-bottom: 5.625rem;
    padding: 0 1.875rem;
  }
`

const Title = styled.h2`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;

  @media (max-width: 1199px) {
    &::after {
      display: none !important;
    }
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 2.375rem;

  a {
    min-width: 15.625rem;
    margin: 0 0.375rem;
  }

  @media (max-width: 767px) {
    a {
      width: 100%;
      margin: 0.75rem 0 0;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`
