import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Includes } from './Includes'

export interface Props {
  bookingButtonURL?: string
  contactsPageURL?: string
  description?: string
  includes?: string
  languageCode: string
  subtitle?: string
  terms?: string
  title?: string
}

export const OfferIntro = memo(function OfferIntro({
  bookingButtonURL,
  contactsPageURL,
  description,
  includes,
  languageCode,
  subtitle,
  terms,
  title,
}: Props) {
  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {title ? <Title>{title}</Title> : null}

        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}

        {terms ? (
          <Wrapper>
            <Label>
              {useVocabularyData('terms-and-conditions', languageCode)}
            </Label>

            <Terms dangerouslySetInnerHTML={{ __html: terms }} />
          </Wrapper>
        ) : null}
      </LeftSide>

      <RightSide>
        <Includes
          bookingButtonURL={bookingButtonURL}
          contactsPageURL={contactsPageURL}
          includes={includes}
          languageCode={languageCode}
        />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;
  padding: 5.5rem 7.431vw 0 12.639vw;

  @media (max-width: 1199px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 1.875rem 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 3.75rem 1.25rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.875rem;

  p {
    margin-block-end: 1.4em;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Wrapper = styled.div`
  border-top: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  margin-top: 3.75rem;
  padding-top: 3.5rem;

  @media (max-width: 767px) {
    margin-top: 1.875rem;
    padding-top: 2rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Terms = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.625rem;
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6.25rem 12.639vw 6.25rem 8.472vw;

  @media (max-width: 1023px) {
    padding: 6.25rem 1.25rem 5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 3rem 1.25rem;
  }
`
