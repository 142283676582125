import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  bookingButtonURL?: string
  contactsPageURL?: string
  includes?: string
  languageCode: string
}

export const Includes = memo(function Includes({
  bookingButtonURL,
  contactsPageURL,
  includes,
  languageCode,
}: Props) {
  return (
    <Container>
      <Label>{useVocabularyData('offer-includes', languageCode)}</Label>
      {includes ? (
        <Description dangerouslySetInnerHTML={{ __html: includes }} />
      ) : null}

      {bookingButtonURL ? (
        <>
          <CTA
            label={useVocabularyData('book', languageCode)}
            rel="noopener"
            target="_blank"
            URL={bookingButtonURL}
            variant="secondary"
          />
          <Divider />
        </>
      ) : null}

      <Info
        label={useVocabularyData('request', languageCode)}
        URL={contactsPageURL}
      />
    </Container>
  )
})

const Container = styled.div``

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin: 1.875rem 0 3.75rem;

  p {
    margin-block-end: 1.4em;
  }

  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 1rem;
      position: relative;
      &:before {
        content: '';
        width: 0.375rem;
        height: 0.375rem;
        background: ${({ theme }) => theme.colors.variants.primaryDark3};
        border-radius: 50%;
        position: absolute;
        top: 0.8125rem;
        left: 0;
      }
    }
  }
`

const CTA = styled(Button)`
  min-width: 15.625rem;
  margin-bottom: 0.625rem;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Info = styled(Button)`
  min-width: 15.625rem;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Divider = styled.div``
